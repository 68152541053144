import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import "../Styles/description.css";

const Description = () => {
  const [apiData, setApiData] = useState({
    title3: '',
    title4: ''
  });

  useEffect(() => {
    // Fetch API data
    axios.get('https://api.gcdassociation.org/api/getuidata/home')
      .then(response => {
        const data = response.data.data[0];
        setApiData({
          title3: data.title3,
          title4: data.title4
        });
      })
      .catch(error => {
        console.error("There was an error fetching the API data!", error);
      });
  }, []);

  return (
    <section id="section-about" className="relative">
      <Container>
        <Row>
          <Col md={4}>
            <h2>
              Welcome to <br />GCDA.
            </h2>
            <div className="small-border sm-left"></div>
          </Col>
          <Col md={4}>
            <p>
              {apiData.title3}
            </p>
          </Col>
          <Col md={4}>
            <blockquote>
              {apiData.title4 }
            </blockquote>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Description;
