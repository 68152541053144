import React, { useEffect, useState } from "react";
import axios from "axios";
import aboutbg1 from "../Assets/aboutbg1.png";
import SolutionStep from "./SolutionStep";
import ExperienceSection from "./ExperienceSection";
import "../Styles/About.css";
import { Helmet } from "react-helmet";

function About() {
  const [data, setData] = useState({ title3: "", title4: "", title5: "" });

  useEffect(() => {
    axios
      .get("https://api.gcdassociation.org/api/getuidata/about")
      .then((response) => {
        const responseData = response.data.data[0];
        setData({
          title3: responseData.title3,
          title4: responseData.title4,
          title5: responseData.title5,
        });
      })
      .catch((error) => {
        console.error("There was an error fetching the API data!", error);
      });
  }, []);
  return (
    <div>
      
      <Helmet>
        <title>About-us</title>
        <meta
          name="description"
          content="Discover expert career counselling services in India, guiding you to the right career path. Our personalized approach helps you achieve your professional goals with confidence."
        />
      </Helmet>
      <div className="about-bg" id="aboutbg">
        <h3 className="about-title">
          <span>About Us</span>
        </h3>
      </div>
      <ExperienceSection />
      <p className="about-description"> {data.title3}</p>
      <div className="about-section" id="about">
        <div className="about-image-content">
          <img src={aboutbg1} alt="Doctor Group" className="about-image1" />
        </div>
        <div className="about-text-content">
          <h4 className="about-text-title">Your Solutions</h4>
          <SolutionStep title="Our Vision" description={data.title4} />
          <SolutionStep
            title="Our Mission"
            description="Our mission at GCDA is to provide personalized, expert career counselling that addresses the unique needs of each client. We are dedicated to offering comprehensive skill development programs that prepare individuals for success in their chosen fields"
          />
          <SolutionStep title="Get Your Solutions" description={data.title5} />
        </div>
      </div>
    </div>
  );
}

export default About;
