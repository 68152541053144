import React from "react";
import Navbar from "../Components/Header";
import Hero from "../Components/Hero";
import Testimonial from "../Components/Testimonials";
import Footer from "../Components/Footer";
import Description from "../Components/Description";
import Services from "../Components/Services";



function Home() {
  return (
    <div className="home-section">
      <meta name="google-site-verification" content="lK9Y7J4JL6jWWkg-xavrP2qgrzgOIJ4gavXPPprCi_o" />
      <Navbar />
      <Hero />
      <Description />
      <Services />
      <Testimonial />
      <Footer />
    </div>
  );
}

export default Home;
