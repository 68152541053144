import React, { useState, useEffect } from "react";
import axios from "axios";
import "../Styles/Services.css";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import servicebg1 from "../Assets/servicebg1.png";

const CardContainer = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  gap: "1rem",
  justifyContent: "center",
  opacity: 0,
  animation: "fadeIn 0.5s ease-out forwards",
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0,
      transform: "translateY(20px)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0)",
    },
  },
});

function Info() {
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);
  const [apiData, setApiData] = useState({
    title5: '',
    title6: '',
    title7: ''
  });

  useEffect(() => {
    axios.get('https://api.gcdassociation.org/api/getuidata/home')
      .then(response => {
        const data = response.data.data[0];
        setApiData({
          title5: data.title5,
          title6: data.title6,
          title7: data.title7
        });
      })
      .catch(error => {
        console.error("There was an error fetching the API data!", error);
      });
  }, []);

  const getDescription = (index) => {
    switch(index) {
      case 0:
        return apiData.title5;
      case 1:
        return apiData.title6;
      case 2:
        return apiData.title7;
      default:
        return '';
    }
  };

  return (
    <div className="info-section" id="services">
      <img src={servicebg1} alt="Service Group" className="service-image" />
      <div className="info-title-content">
        <div className="info-title">
          <h8>WHAT WE DO</h8>
        </div>
        <div className="info-title1">
          <h3>Our Services</h3>
        </div>
        <div className="button-container">
          {['Personal Counselling', 'Career Assessment', 'Workshops'].map((title, index) => (
            <button
              key={index}
              type="button"
              className={`btn ${selectedCardIndex === index ? 'btn-success' : 'btn-primary'}`}
              onClick={() => setSelectedCardIndex(index)}
            >
              {title}
            </button>
          ))}
        </div>
        <div className="services-content">
          <CardContainer>
            {[0, 1, 2].map((index) => (
              <Card
                key={index}
                sx={{
                  width: "100%",
                  maxWidth: 450,
                  margin: "10px",
                  display: selectedCardIndex === index ? 'block' : 'none',
                  border: 'none',
                  boxShadow: 'none',
                }}
              >
                <CardContent>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ fontSize: "17px" }}
                    className={selectedCardIndex === index ? 'text-effect' : ''}
                  >
                    {getDescription(index)}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </CardContainer>
        </div>
      </div>
    </div>
  );
}

export default Info;
