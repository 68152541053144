import React, { useState,useEffect } from 'react';
import Header from "../Components/Header";
import Footer from "../Components/Footercertification";

import certification from "../Assets/gallery/certification.png";
import Seminar30 from "../Assets/gallery/Seminar30.png";

import { Helmet } from 'react-helmet';

import { useParams, Link } from "react-router-dom";


import "../Styles/Seminar.css";
import { blue } from '@material-ui/core/colors';

function Seminar() {
    const [cities, setCities] = useState([]);
    const [description, setdescription] = useState([]);

    const [selectedCity, setSelectedCity] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [articles, setArticles] = useState([]);

    const { city, state } = useParams(); useEffect(() => {
        if (city) {

            setSelectedCity(city);
            setSelectedState(state);
        }
    }, [city]);

    useEffect(() => {
        console.log("selectedCity", selectedCity, selectedState)

        if (selectedCity) {
            async function fetchArticlesByCity() {
                try {
                    const response = await fetch(`https://api.gcdassociation.org/api/certification/${selectedCity}/${selectedState}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    if (data.data.length === 0) {
                        setArticles([]);
                    } else {

                        setArticles(data.data[0].article.split('\n').filter(paragraph => paragraph.trim() !== ''));

                        setCities(data.data[0].city);
                        setdescription(data.data[0].description)
                    }
                } catch (error) {
                    console.error('Error fetching articles:', error);
                }
            }
            fetchArticlesByCity();
        }
    }, [selectedCity]);
    return (
        <>
            <div>
                <Header />
                <Helmet>
                    <title>Career Counselling  Certification in {cities}</title>
                    <meta name="description" content={description} />
                </Helmet>
                <div className="seminar-heading"><h1>Career Counselling Certification in {cities}</h1></div>
                <img src={certification} alt="Service Group" className="seminar-bg" />
                <div className="certification-heading2"><h2>Begin Your Path with Career Counselling  Course in {cities}</h2></div>
                <ul class="spaced-list">
                <p className='seminar-articles'>{articles.map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                ))}</p>
                </ul>
                <div className="certification-heading2"><h2>What Does a Career Counsellor Do? Explore Your Role</h2></div>

                <div className="certification-text">
                    As a career counsellor, you play a crucial role in guiding students and working professionals through their career journeys. By leveraging career assessments, test reports, and counseling sessions, you assist individuals in discovering their interests and abilities. This process is integral to our career counselling course, which prepares you to become a certified career counsellor.
                </div>
                <ul class="spaced-list">
                    <li>Career Guidance: Assist individuals in crafting a comprehensive plan to achieve their career objectives. Our career counselling course emphasizes practical techniques essential for effective career coaching and mentorship.</li>
                    <li>Overcoming Challenges: Address obstacles that may hinder academic or career success. Through our career coaching program, you'll learn strategies to support clients in overcoming these challenges.</li>
                    <li>Educational Support: Provide crucial information about colleges, admission criteria, entrance exams, and cut-offs. This knowledge is vital for career counsellors to empower their clients with accurate and timely information.</li>
                </ul>
                <div className="certification-heading2"><h2>Who should enroll in our career counseling Certification in {cities}</h2></div>

                <div className="certification-heading3">
                    ideal for individuals passionate about mentoring and guiding others in their career paths. Whether you aspire to excel as a career counsellor or enhance your mentoring skills, our program offers essential certification and expertise
                </div>

                <div className="proposal-grid">
                    <div className="Seminarbg-item">
                        <strong>Open to All:</strong> Our program welcomes anyone eager to help others navigate their careers, including retired professionals looking to leverage their experience.
                        <br></br>
                        <strong>Essential Skills Development:</strong> Gain expertise in connecting with clients, understanding their career needs, and guiding them effectively.


                    </div>
                    <div className="Seminarbg-item">
                        <strong>Essential Skills Development:</strong>
                        Eligibility Requirements: Open to psychology graduates and minimum graduates in any discipline, ensuring a strong foundation in career guidance.
                    </div>

                </div>
                <div className='button-grid'>
                    <div className='certification-button'>
                        <a
                            className="btn btn-lg btn-block"
                            style={{ color: '#fff', backgroundColor: '#ff8501', fontSize: '20px' }}
                            href="https://allegiance-educare.in/storage/uploads/career-counselling-course-module_z21Ca.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Download Course Outline
                        </a>
                    </div>
                    <div className='certification-button'>
                        <a
                            className="btn btn-lg btn-block"
                            style={{ color: '#fff', backgroundColor: '#ff8501', fontSize: '20px' }}
                            href="https://allegiance-educare.in/career-counselling-certification-course">
                            Know more about certification
                        </a>
                    </div>
                </div>
                <div className="certification-heading2"><h2>Why Choose Our Career Counselling course in {cities}</h2></div>                <div className="proposal-grid">
                    <div className="Seminarbg-item">
                        <div style={{ textAlign: "center" }}>
                            <br />
                            <span style={{ fontSize: "20px" }}>
                                <u>1. Expert Guidance</u>
                            </span>
                            <div className="certification-heading3">
                                Explore our premier career counseling course led by industry experts with extensive field experience. Harness the invaluable insights and guidance of professionals who have consistently empowered individuals to achieve fulfilling and successful careers. Acquire the expertise and knowledge essential for excelling in career development and making a meaningful impact in the lives of others. Enroll today to enhance your career counseling capabilities and unlock new professional opportunities..</div>
                        </div>


                    </div>
                    <div className="Seminarbg-item">
                        <div style={{ textAlign: "center" }}>
                            <br />
                            <span style={{ fontSize: "20px" }}>
                                <u>2. Comprehensive Curriculum</u>
                            </span>
                            <div className="certification-heading3">
                                Discover our comprehensive offline training program designed to equip you with a thorough grasp of career counselling principles, assessment tools, and effective coaching techniques. Delve into diverse counselling strategies and personalized approaches tailored to enhance your skills as a proficient career counsellor. Enroll today to expand your expertise and advance your career in guiding individuals towards successful professional paths.
                            </div>
                        </div>


                    </div>

                </div>

                <div className="certification-heading2"><h2>Transform Your Career: Become a Certified Career Counsellor in {cities}</h2></div>
                <div className="certification-heading3">
                    Discover our comprehensive offline training program designed to equip you with a thorough grasp of career counselling principles, assessment tools, and effective coaching techniques. Delve into diverse counselling strategies and personalized approaches tailored to enhance your skills as a proficient career counsellor. Enroll today to expand your expertise and advance your career in guiding individuals towards successful professional paths.
                </div>
                <img src={Seminar30} alt="Service Group" className="seminar-bg" />
                <div class="video-container">
                    <iframe width="1052" height="652" src="https://www.youtube.com/embed/ZQYxaC0pnZY" title="Programs for Career Counselling Professionals" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <Footer />
            </div>

        </>
    );
}

export default Seminar;
