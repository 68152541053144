import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

function PlanCard(props) {
  return (
    <div className="dt-card">
      <p className="dt-card-name">{props.name}</p>
      {/* <p className="dt-card-title">{props.title}</p> */}
      <p className="dt-card-price">{props.price}</p>
      <p className="dt-card-a">{props.a}</p>
      <p className="dt-card-b">{props.b}</p>
      <p className="dt-card-c">{props.c}</p>
      <p className="dt-card-d">{props.d}</p>
      <p className="dt-card-e">{props.e}</p>
      <p className="dt-card-f">{props.f}</p>
      <p className="dt-card-g">{props.g}</p>
      <a href="https://gcdassociation.allegiance-educare.in/plans" class="btn btn-success" role="button">Know more</a>



      
    </div>
  );
}

export default PlanCard;
