import React from "react";
import Header from "../Components/Header";
import Contact from "../Components/Contact";
import Footer from "../Components/Footer";





function ContactUs() {
  return (
    <div className="contact-section">
      <Header />
      <Contact/>
      <Footer/>

    </div>
  );
}
export default ContactUs;
