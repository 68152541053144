import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import "../Styles/ExperienceSection.css";

const Experience = () => {
  const [data, setData] = useState({ title1: '', title2: '' });

  useEffect(() => {
    axios.get('https://api.gcdassociation.org/api/getuidata/about')
      .then(response => {
        const responseData = response.data.data[0];
        setData({
          title1: responseData.title1,
          title2: responseData.title2
        });
      })
      .catch(error => {
        console.error("There was an error fetching the API data!", error);
      });
  }, []);

  return (
    <section id="section-about" className="relative">
      <Container>
        <Row>
          <Col md={4}>
            <div className="titel-text">10 Years</div>
          </Col>
          <Col md={4}>
            <h2 className="highlighted-text">
              {data.title1}
            </h2>
          </Col>
          <Col md={4}>
            <h6>
              {data.title2}
            </h6>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Experience;
