import React from "react";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';

import PlanCard from "./PlanCard";
import Doctor from "../Assets/bg-1.png";
import profile1 from "../Assets/planbg.png";
import profile2 from "../Assets/gallery/gallery-3.jpg";
// import profile3 from "../Assets/plans/plan3.jpg";
// import profile4 from "../Assets/plans/plan4.jpg";
// import profile5 from "../Assets/plans/plan5.jpg";
import { Helmet } from 'react-helmet';


import "../Styles/Plans.css";

function Plans() {
    return (
        <div>
            <Helmet>
                <title>Career Counselling Plans | Best Career Counsellor</title>
                <meta name="description" content="Unlock your potential with expert career counselling services in India. Navigate your professional journey confidently. Get started today!" />
            </Helmet>
            <img src={profile1} alt="Service Group" className="plan1-bg" />
            <div
                className="planbg"
                style={{
                    backgroundImage: `url(${Doctor})`,
                    backgroundSize: "cover",
                    marginTop: "50px",
                    backgroundPosition: "center center",
                    height: "150",
                }}
            >
                <h1 className="top-heading">Everything You Need</h1>
                <h4 className="second-heading">
                    For students and career assessment test can help you narrow down your job choices and choose a career path that is compatible with your interests, skills, values, and personality. The planning of a career is among the most important decision of our life. We try our best to help students in their career planning by bringing all career-related problems and solutions under one roof.
                </h4>
                <div className="dt-cards-content">
                    <div className="top-heading-placeholder"></div>
                    <PlanCard
                        name="Stream Selector"
                        title="Get started with our Basic Plan and enjoy the essentials. Perfect for individuals looking to kickstart their fitness journey."
                        price="Rs.1000"
                        a="3rd Std to 10th Science  Commerce  Art"
                        b="Career Interest, Aptitude, Personality Test"
                        c="Career Assessment Report to shortlist appropriate Stream"
                        d="Automated Career Assessment Report to shortlist appropriate Stream"
                        e="Face to Face/Video Counselling with Mentor to shortlist appropriate Stream"
                        f="Post Counselling Services : Student Helpline, Study Abroad Helpline"
                        g="3 Aspired Careers suitability Analysis"
                    />
                    <PlanCard
                        name="Degree Selector"
                        title="Upgrade to our Premium Plan for a more personalized experience. Enjoy advanced features and exclusive access to expert trainers."
                        price="Rs.2000"
                        a="3rd Std to 10th Science  Commerce  Art"
                        b="Career Interest, Aptitude, Personality Test"
                        c="Career Assessment Report to shortlist appropriate Stream"
                        d="Automated Career Assessment Report to shortlist appropriate Stream"
                        e="Face to Face/Video Counselling with Mentor to shortlist appropriate Stream"
                        f="Post Counselling Services : Student Helpline, Study Abroad Helpline"
                        g="3 Aspired Careers suitability Analysis"
                    />
                    <PlanCard
                        name="Working Professionals"
                        title="Take your fitness to the next level with our Pro Plan. Get access to premium workouts, nutrition plans, and 24/7 support."
                        price="Rs.3000"
                        a="3rd Std to 10th Science  Commerce  Art"
                        b="Career Interest, Aptitude, Personality Test"
                        c="Career Assessment Report to shortlist appropriate Stream"
                        d="Automated Career Assessment Report to shortlist appropriate Stream"
                        e="Face to Face/Video Counselling with Mentor to shortlist appropriate Stream"
                        f="Post Counselling Services : Student Helpline, Study Abroad Helpline"
                        g="3 Aspired Careers suitability Analysis"
                    />
                </div>
            </div>
        </div>
    );
}

export default Plans;
