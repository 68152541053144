import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';

import logo from '../Assets/logo.png'; // Adjust the path to your logo
import '../Styles/Header.css'; // Adjust the path to your CSS file

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  return (
    <nav className={`navbar navbar-expand-lg ${scrolled ? 'bg-white' : 'bg-body-tertiary'} fixed-top`}>
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img alt="Logo" src={logo} className="logo" />
        </Link>
        <div className="need-help-mobile d-lg-none">
          <button type="button" className="btn btn-success">Need Help? 091360 05039</button>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* "Need Help?" button outside of the collapse */}
       

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link className={`nav-link ${location.pathname === '/' ? 'active' : ''}`} to="/">Home</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${location.pathname === '/about-us' ? 'active' : ''}`} to="/about-us">About</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${location.pathname === '/plan' ? 'active' : ''}`} to="/plan">Plans</Link>
            </li>
            <Nav className="mr-auto">
              <NavDropdown title={<span> Services</span>}
                id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="/Career-Counsellor">
                  Career Counselling
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Career-counselling-seminar">
                  Seminar
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Certification">
                  Certification
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <li className="nav-item">
              <Link className={`nav-link ${location.pathname === '/Contacts' ? 'active' : ''}`} to="/Contacts">Contact Us</Link>
            </li>
          </ul>
        </div>

        {/* "Need Help?" button for desktop view */}
        <div className="need-help-desktop d-none d-lg-block">
          <button type="button" className="btn btn-success">Need Help? 091360 05039</button>
        </div>
      </div>
    </nav>
  );
};

export default Header;
