import React, { useEffect, useState } from 'react';
import Header from "./Header";
import Footerseminar from "./Footerseminar";
import Seminar1 from "../Assets/gallery/Seminar1.png";
import Seminar2 from "../Assets/gallery/Seminar2.png";
import Seminar3 from "../Assets/gallery/Seminar3.png";
import Seminar4 from "../Assets/gallery/Seminar4.png";
import Seminar5 from "../Assets/gallery/Seminar5.png";
import Seminar6 from "../Assets/gallery/Seminar6.png";
import Seminar7 from "../Assets/gallery/Seminar7.png";
import Seminar8 from "../Assets/gallery/Seminar8.png";
import Seminar9 from "../Assets/gallery/Seminar9.png";
import Seminar10 from "../Assets/gallery/Seminar10.png";
import Seminar11 from "../Assets/gallery/Seminar11.png";
import Seminar12 from "../Assets/gallery/Seminar12.png";
import Seminar13 from "../Assets/gallery/Seminar13.png";
import Seminar14 from "../Assets/gallery/Seminar14.png";
import Seminar15 from "../Assets/gallery/Seminar15.png";
import Seminar16 from "../Assets/gallery/Seminar16.png";
import Seminar17 from "../Assets/gallery/Seminar17.png";
import Seminar18 from "../Assets/gallery/Seminar18.png";
import Seminar19 from "../Assets/gallery/Seminar19.png";
import Seminar20 from "../Assets/gallery/Seminar20.png";
import Seminar21 from "../Assets/gallery/Seminar21.png";
import Seminar22 from "../Assets/gallery/Seminar22.png";
import Seminar23 from "../Assets/gallery/Seminar23.png";
import Seminar24 from "../Assets/gallery/Seminar24.png";
import Seminar25 from "../Assets/gallery/Seminar25.png";
import Seminar26 from "../Assets/gallery/Seminar26.png";
import Seminar27 from "../Assets/gallery/Seminar27.png";
import Seminar28 from "../Assets/gallery/Seminar28.png";
import Seminar29 from "../Assets/gallery/Seminar29.png";

import { Helmet } from 'react-helmet';
import { useParams, Link } from "react-router-dom";



import "../Styles/Seminar.css";

function Seminar() {
    const [cities, setCities] = useState([]);
    const [description, setdescription] = useState([]);

    const [selectedCity, setSelectedCity] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [articles, setArticles] = useState([]);

    const { city, state } = useParams(); useEffect(() => {
        if (city) {

            setSelectedCity(city);
            setSelectedState(state);
        }
    }, [city]);

    useEffect(() => {
        console.log("selectedCity", selectedCity, selectedState)

        if (selectedCity) {
            async function fetchArticlesByCity() {
                try {
                    const response = await fetch(`https://api.gcdassociation.org/api/seminar/${selectedCity}/${selectedState}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    if (data.data.length === 0) {
                        setArticles([]);
                    } else {

                        setArticles(data.data[0].article.split('\n').filter(paragraph => paragraph.trim() !== ''));

                        setCities(data.data[0].city);
                        setdescription(data.data[0].description)
                    }
                } catch (error) {
                    console.error('Error fetching articles:', error);
                }
            }
            fetchArticlesByCity();
        }
    }, [selectedCity]);


    return (
        <>
            <div>
                <Header />
                <Helmet>
                    <title>Career Counselling & Guidance Seminar {cities}</title>
                    <meta name="description" content={description} />
                </Helmet>
                <div className="seminar-heading">
                    <h1>career counselling seminar in {cities}</h1>
                </div>
                <img src={Seminar1} alt="Service Group" className="seminar-bg" />
                <p className='seminar-articles'>{articles.map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                ))}</p>
                <img src={Seminar2} alt="Service Group" className="seminar-bg" />
                <div className="topics-grid">

                    <div className="topic-item">
                        <img src={Seminar10} alt="Service Group" className="seminar-bg" />
                    </div>
                    <div className="topic-item">
                        <img src={Seminar11} alt="Service Group" className="seminar-bg" />
                    </div><div className="topic-item">
                        <img src={Seminar12} alt="Service Group" className="seminar-bg" />
                    </div><div className="topic-item">
                        <img src={Seminar13} alt="Service Group" className="seminar-bg" />
                    </div>
                </div>
                <div className="seminar-heading2">
                    <h2> Our Career Guidance Seminar Snapshots in {cities}</h2>
                </div>
                <div className="scroll-wrapper">
                    <div className="Seminarbg-grid">
                        <div className="Seminarbg-item">
                            <img src={Seminar3} alt="Service Group" className="seminar-bg2" />
                        </div>
                        <div className="Seminarbg-item">
                            <img src={Seminar4} alt="Service Group" className="seminar-bg2" />
                        </div>
                        <div className="Seminarbg-item">
                            <img src={Seminar5} alt="Service Group" className="seminar-bg2" />
                        </div>
                        <div className="Seminarbg-item">
                            <img src={Seminar6} alt="Service Group" className="seminar-bg2" />
                        </div>
                        <div className="Seminarbg-item">
                            <img src={Seminar18} alt="Service Group" className="seminar-bg2" />
                        </div>

                    </div>
                </div>
                <div className="Seminarbg-grid">
                    <div className="Seminarbg-item">
                        <Link to="/Career-Counsellor">
                            <img src={Seminar7} alt="Service Group" className="seminar-bg" />
                        </Link>
                    </div>
                    <div className="Seminarbg-item">
                        <a href="https://allegiance-educare.in/career-counselling-certification-course">
                            <img src={Seminar8} alt="Service Group" className="seminar-bg" />
                        </a>
                    </div>
                </div>


                <div className="seminar-heading2">  <h2>Explore Our Proposals</h2></div>

                <div className="proposal-grid">
                    <div className="Seminarbg-item">
                        <div style={{ textAlign: "center" }}>
                            <br />
                            <u>
                                <span style={{ fontSize: "20px" }}>
                                    <strong>Proposal 1:</strong>
                                </span>
                            </u>
                        </div>

                        <li>Whitelabel - Lifetime Access at Rs.25,000</li>
                        <li>Premium Career Counseling for Students (Rs.2999/Rs.3499):</li>
                        <div>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;-&nbsp;Algorithm Report with shortlisted careers from 500 careers</div>
                        <div>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;-&nbsp;Face-to-Face counselling Rs.200 - Rs.500 per test</div>
                    </div>
                    <div className="Seminarbg-item">
                        <div style={{ textAlign: "center" }}>
                            <br />
                            <u>
                                <span style={{ fontSize: "20px" }}>
                                    <strong>Proposal 2:</strong>
                                </span>
                            </u>
                        </div>

                        <li>Whitelabel - Rs.25,000 lifetime</li>
                        <li>Free Career Interest Test used by:</li>
                        <div>
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;-&nbsp;Colleges for admission counseling  </div>
                        <div>
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;-&nbsp;NGOs for shortlisting skill-based courses
                        </div>
                    </div>
                </div>
                <div className="seminar-heading3">  <h2>Proprietory Trademark Technologies</h2></div>
                <div className="scroll-wrapper">
                    <div className="Seminarbg-grid">
                        <div className="Seminarbg-item">
                            <img src={Seminar14} alt="Service Group" className="seminar-bg1" />
                        </div>
                        <div className="Seminarbg-item">
                            <img src={Seminar15} alt="Service Group" className="seminar-bg1" />
                        </div>
                        <div className="Seminarbg-item">
                            <img src={Seminar16} alt="Service Group" className="seminar-bg1" />
                        </div>
                        <div className="Seminarbg-item">
                            <img src={Seminar17} alt="Service Group" className="seminar-bg1" />
                        </div>

                    </div>
                </div>
                <div className="seminar-heading3">  <h2>Recognitions and Alliances</h2></div>
                <div className="scroll-wrapper">
                    <div className="Seminarbg-grid">
                        <div className="Seminarbg-item">
                            <img src={Seminar19} alt="Service Group" className="seminar-bg3" />
                        </div>
                        <div className="Seminarbg-item">
                            <img src={Seminar20} alt="Service Group" className="seminar-bg3" />
                        </div>
                        <div className="Seminarbg-item">
                            <img src={Seminar21} alt="Service Group" className="seminar-bg3" />
                        </div>
                        <div className="Seminarbg-item">
                            <img src={Seminar22} alt="Service Group" className="seminar-bg4" />
                        </div>
                        <div className="Seminarbg-item">
                            <img src={Seminar23} alt="Service Group" className="seminar-bg5" />
                        </div>
                        <div className="Seminarbg-item">
                            <img src={Seminar24} alt="Service Group" className="seminar-bg6" />
                        </div>

                    </div>
                </div>
                <div className="seminar-heading3">  <h2>News & Media coverage</h2></div>
                <div className="scroll-wrapper">
                    <div className="Seminarbg-grid3">
                        <div className="Seminarbg-item3">
                            <img src={Seminar25} alt="Service Group" className="seminar-bg3" />
                        </div>
                        <div className="Seminarbg-item3">
                            <img src={Seminar26} alt="Service Group" className="seminar-bg3" />
                        </div>
                        <div className="Seminarbg-item3">
                            <img src={Seminar27} alt="Service Group" className="seminar-bg3" />
                        </div>
                        <div className="Seminarbg-item3">
                            <img src={Seminar28} alt="Service Group" className="seminar-bg4" />
                        </div>
                        <div className="Seminarbg-item3">
                            <img src={Seminar29} alt="Service Group" className="seminar-bg5" />
                        </div>
                    </div>
                </div>
                <Footerseminar />

            </div>

        </>
    );
}

export default Seminar;
