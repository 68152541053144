import React, { useEffect, useState } from 'react';
import { Card, Button, Row } from 'react-bootstrap';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "../Styles/City.css";
// Import statements...
import { useParams } from "react-router-dom";
import Navbar from "./Header";
import Footer from "./Footer";
import "../Styles/Careercounselling.css";
import { Helmet } from 'react-helmet';

import { Link } from 'react-router-dom'; // Assuming you're using React Router
import careerbg1 from "../Assets/careerbg1.png";
import careerbg2 from "../Assets/careerbg2.png";
import careerbg3 from "../Assets/careerbg3.png";
import careerbg4 from "../Assets/careerbg4.png";
import careerbg5 from "../Assets/careerbg5.png";
import careerbg6 from "../Assets/careerbg6.png";
import careerbg7 from "../Assets/careerbg7.png";
import careerbg8 from "../Assets/careerbg8.png";


function Data() {
  const [description, setdescription] = useState([]);

  const [selectedCity, setSelectedCity] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedArea, setSelectedArea] = useState('');

  const [articles, setArticles] = useState([]);
  const [areaname, setArea] = useState([]);

  const { city, state,area } = useParams();

  useEffect(() => {
    if (city) {
      setSelectedCity(city);
      setSelectedState(state);
      setSelectedArea(area);

    }
  }, [city]);

  useEffect(() => {
    if (selectedCity) {
      async function fetchArticlesByCity() {
        try {
          const response = await fetch(`https://api.gcdassociation.org/api/area/${selectedCity}/${selectedState}/${selectedArea}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          });
          const data = await response.json();
          if (data.data.length === 0) {
            setArticles([]);
          } else {
          setArea( data.data[0]["Office Name"])
            setArticles(data.data);
            setdescription(data.data[0].description)
          }
        } catch (error) {
          console.error('Error fetching articles:', error);
        }
      }
      fetchArticlesByCity();
    }
  }, [selectedCity]);

  return (
    <div>

      {articles.length > 0 && (
        <div>
          <Navbar />
          <div className="section-container">
            <Helmet>
              <title>Career Counselling in {areaname} | Best Career Guidance</title>
              <meta name="description" content={description} />
            </Helmet>
            <div
              className="hero-section"
              style={{
                backgroundImage: `url(${careerbg1})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                height: "87vh",
              }}
            >
              <div className="text-section">
                <h1 className="career-title">Career Counselling in {areaname}</h1>
                <p className="career-descritpion">
                  <br /> today's fast-paced and ever-evolving job market, navigating one's career path can be daunting. Career counselling has emerged as a crucial service to help individuals make informed decisions about their professional lives. This process involves a professional counselor guiding individuals through career exploration, career changes, and professional development. The importance of career counselling cannot be overstated, as it offers personalized guidance that takes into account an individual's strengths, interests, and the current job market trends.
                </p>
              </div>
            </div>
          </div>
          <div className="container mt-4">
            <div className="row">
              {articles.map((article, index) => {
                const paragraphs = article.article;
                return (
                  <Row key={index} className="mb-4">
                    <Card.Body>
                      <div className="paragraph" dangerouslySetInnerHTML={{ __html: paragraphs }}></div>
                    </Card.Body>
                    <div className="career-description1">
                      <img src={careerbg2} alt="Description Image" className="description-image" />
                      <div className="text-container">
                        <div className="carrer-what">Career Counselling Services</div>

                        <div className="carrer-what1">Career counselling services encompass a range of activities designed to assist individuals in managing their career development. These services include:</div>
                        <ul>
                          <li><strong>Career Assessment and Testing:</strong>: Tools and tests to help identify an individual's skills, interests, and personality traits, which can guide career choices.</li>
                          <li><strong>Career Exploration:</strong> Information about different careers, including required education, job outlook, and salary expectations.</li>
                          <li><strong>Resume and Cover Letter Writing:</strong> Assistance in creating professional resumes and cover letters that highlight an individual's strengths and experiences.
                            .</li>
                          <li><strong>Job Search Strategies:</strong>Techniques for effective job searching, including networking, interviewing skills, and utilizing job search engines.
                          </li>
                          <li><strong>Career Development Planning:</strong> Creating a long-term career plan that includes setting achievable goals and identifying the steps necessary to reach them.
                          </li>
                          <li><strong>Work-Life Balance:</strong>Strategies to manage career and personal life effectively, reducing stress and increasing job satisfaction.
                            .</li>
                        </ul>
                      </div>
                    </div>
                    <div className="career-description2">
                      <img src={careerbg3} alt="Description Image" className="description-image" />
                      <div className="text-container">
                        <div className="carrer-what">Benefits of Career Counselling</div>
                        <div className="carrer-what1">The benefits of career counselling are extensive and can have a significant impact on an individual's professional and personal life. Some of the key benefits include:</div>
                        <ul>
                          <li><strong>Clarity and Direction:</strong>:Career counselling helps individuals gain a clear understanding of their career goals and the steps needed to achieve them.</li>
                          <li><strong>Increased Confidence:</strong> By identifying strengths and areas for improvement, career counselling boosts self-confidence and empowers individuals to pursue their career aspirations.</li>
                          <li><strong>Improved Job Satisfaction:</strong>With guidance from a career counselor, individuals can find careers that align with their interests and values, leading to higher job satisfaction
                            .</li>
                          <li><strong>Better Decision-Making:</strong>Career counselors provide valuable insights and information that aid in making informed career decisions
                          </li>
                          <li><strong>Stress Reduction:</strong>By providing a clear plan and support, career counselling can reduce the anxiety associated with career transitions and job searching.
                            .</li>
                        </ul>
                      </div>
                    </div>
                    <div className="career-description1">
                      <img src={careerbg4} alt="Description Image" className="description-image" />
                      <div className="text-container">
                        <div className="carrer-what">Career Counselling Services in {areaname}</div>
                        <div className="carrer-what1">GCDA offers a plethora of career counselling services catering to different needs. These services range from one-on-one counselling sessions to comprehensive career development programs. Here are some common types of career counselling services available in {areaname}:</div>
                        <ul>
                          <li><strong>School and College Counselling:</strong>:Many schools and colleges in {areaname} have in-house career counselors who help students choose their streams and courses based on their interests and academic performance.</li>
                          <li><strong>Professional Career Counselors:</strong>There are numerous professional career counselling firms and individual counselors in {areaname} who offer specialized services, including career assessments, resume building, and interview preparation.</li>
                          <li><strong>Online Career Counselling:</strong>With the advent of technology, online career counselling has become popular. Many platforms offer virtual counselling sessions, making it convenient for individuals to seek guidance from the comfort of their homes.</li>
                          <li><strong>Corporate Career Counselling:</strong>: Companies in {areaname} often hire career counselors to provide guidance to their employees, helping them with career development and progression within the organization.
                          </li>
                          <li><strong>Specialized Counselling:</strong>Some counselors specialize in specific fields such as arts, engineering, or management, providing targeted advice for those particular career paths.</li>
                        </ul>
                      </div>
                    </div>
                    <div className="career-description2">
                      <img src={careerbg5} alt="Description Image" className="description-image" />
                      <div className="text-container">
                        <div className="carrer-what">Benefits of Career Counselling in {areaname}</div>
                        <div className="carrer-what1">Choosing the right career counselor in {areaname} can offer several benefits that significantly enhance one’s professional journey. Here are some key advantages:
                        </div>

                        <ul>
                          <li><strong>Informed Decision Making:</strong> Career counselors provide in-depth information about various career options, helping individuals make informed decisions.</li>
                          <li><strong>Reduced Stress and Anxiety:</strong> The job market in {areaname} can be highly competitive, leading to stress and anxiety. Career counselors offer strategies to manage these challenges effectively.</li>
                          <li><strong>Enhanced Employability:</strong> By identifying the right skills and qualifications needed for a particular career, counselors help individuals become more employable.</li>
                          <li><strong>Networking Opportunities:</strong> Career counselors often have extensive networks in various industries. They can provide valuable connections that might be beneficial for job placements or internships.</li>
                          <li><strong>Career Transition Support:</strong> For those looking to switch careers, counselors offer guidance on how to transition smoothly, including retraining and upskilling.</li>
                        </ul>
                      </div>
                    </div>
                    <div className="career-description1">
                      <img src={careerbg6} alt="Description Image" className="description-image" />
                      <div className="text-container">

                        <div className="carrer-what">Finding the Right Career Counselor in {areaname}</div>
                        <div className="carrer-what1">CChoosing the right career counselor is crucial for receiving effective guidance. Here are some tips to find the best career counselor:</div>
                        <ul>
                          <li><strong>Research and Reviews:</strong> Start by researching online and reading reviews. Look for counselors with positive testimonials and a strong reputation.</li>
                          <li><strong>Qualifications and Experience:</strong> Ensure that the counselor has the necessary qualifications and relevant experience. A background in psychology or human resources can be beneficial.</li>
                          <li><strong>Specialization:</strong> If you have a specific career in mind, look for counselors who specialize in that field.</li>
                          <li><strong>Personal Connection:</strong> It’s important to feel comfortable with your counselor. Schedule an initial consultation to see if you can build a good rapport.</li>
                          <li><strong>Success Stories:</strong> Ask the counselor about their success stories. This can give you an idea of how effective their guidance has been for others.</li>
                        </ul>
                      </div>
                    </div>
                    <div className="career-description2">
                      <img src={careerbg7} alt="Description Image" className="description-image" />
                      <div className="text-container">


                        <div className="carrer-what">The Process of Career Counselling</div>
                        <div className="carrer-what1">Understanding the process of career counselling can help individuals know what to expect and how to prepare. Here is a typical career counselling process:
                        </div>
                        <ul>
                          <li><strong>Initial Consultation:</strong> The process begins with an initial consultation where the counselor gets to know the individual’s background, interests, and goals.</li>
                          <li><strong>Assessment:</strong> Various assessments are conducted to evaluate the individual’s skills, interests, personality, and values. These assessments help in identifying suitable career options.</li>
                          <li><strong>Career Exploration:</strong> Based on the assessment results, the counselor provides information about different career paths that align with the individual’s profile.</li>
                          <li><strong>Planning and Goal Setting:</strong> The counselor helps in setting short-term and long-term career goals. They also provide a roadmap to achieve these goals.</li>
                          <li><strong>Skill Development:</strong> Counselors often recommend skill development courses or certifications that can enhance employability.</li>
                          <li><strong>Job Search Assistance:</strong> For those looking for job opportunities, counselors provide job search strategies, resume building tips, and interview preparation.</li>
                          <li><strong>Follow-Up Sessions:</strong> Regular follow-up sessions are conducted to track progress and make any necessary adjustments to the career plan.</li>
                        </ul>
                      </div>
                    </div>
                    <div className="career-description1">
                      <img src={careerbg8} alt="Description Image" className="description-image" />
                      <div className="text-container">

                        <div className="carrer-what">Career Guidance for Different Age Groups</div>
                        <div className="carrer-what1">Career counselling is not limited to any specific age group. Here’s how career guidance can benefit different age groups in Inida:
                        </div>
                        <ul>
                          <li><strong>Students:</strong> Career counselling helps students choose the right stream and courses, aligning their academic choices with their career aspirations.</li>
                          <li><strong>Fresh Graduates:</strong> For fresh graduates, career counselling provides guidance on job search strategies, resume building, and interview preparation.</li>
                          <li><strong>Mid-Career Professionals:</strong> Professionals looking to switch careers or advance in their current fields can benefit from career counselling to identify the right opportunities and necessary skills.</li>
                          <li><strong>Senior Professionals:</strong> For senior professionals, career counselling can provide insights on leadership roles, career progression, and retirement planning.</li>
                        </ul>
                      </div>
                    </div>
                    <div className="career-description3">
                      <h4><Link to="/contacts" className="btn btn-primary"> Contacts For CareeCounselling</Link>
                      </h4>
                    </div>                  </Row>
                );
              })}
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      )}
    </div>
  );
}

export default Data;
