import React from "react";
import Plans from "../Components/Plan";
import Header from "../Components/Header";
import Footer from "../Components/Footer";





function Plan() {
  return (
    <div className="home-section">
      <Header />
      <Plans />
      <Footer/>

    </div>
  );
}
export default Plan;
