import React, { useState } from 'react';
import '../Styles/ContactUs.css';
import { Container, Row, Col, Alert } from 'react-bootstrap';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: ''
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch('https://api.gcdassociation.org/api/sendmessage', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
    .then(response => response.json())
    .then(data => {
      console.log('Success:', data);
      setSuccessMessage('Your message has been sent successfully!');
      setErrorMessage('');
      // Clear the form
      setFormData({
        name: '',
        phone: '',
        email: '',
        message: ''
      });
    })
    .catch((error) => {
      console.error('Error:', error);
      setErrorMessage('There was an error sending your message. Please try again later.');
      setSuccessMessage('');
    });
  };

  return (
    <div className="contact-us-container">
      <Container>
        <Row>
          <Col>
            <h1>Contact Us</h1>
            <p>Get in touch with us for any inquiries or feedback.</p>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="contact-form">
              <h3>Send us a message:</h3>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="name">Name:</label>
                  <input 
                    type="text" 
                    id="name" 
                    name="name" 
                    value={formData.name} 
                    onChange={handleChange} 
                    required 
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phone">Phone No.:</label>
                  <input 
                    type="tel" 
                    id="phone" 
                    name="phone" 
                    value={formData.phone} 
                    onChange={handleChange} 
                    required 
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email:</label>
                  <input 
                    type="email" 
                    id="email" 
                    name="email" 
                    value={formData.email} 
                    onChange={handleChange} 
                    required 
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="message">Message:</label>
                  <textarea 
                    id="message" 
                    name="message" 
                    value={formData.message} 
                    onChange={handleChange} 
                    required 
                  ></textarea>
                </div>
                <button type="submit">Send</button>
              </form>
              {successMessage && <Alert variant="success" className="mt-3">{successMessage}</Alert>}
              {errorMessage && <Alert variant="danger" className="mt-3">{errorMessage}</Alert>}
            </div>
          </Col>
          <Col md={6}>
            <div className="contact-info-box">
              <h3>Office</h3>
              <p>08 W 36th St, New York</p>
            </div>
            <div className="contact-info-box">
              <h3>Phone</h3>
              <p>91360 05039</p>
            </div>
            <div className="contact-info-box">
              <h3>Email</h3>
              <p>gcdassociation.org@gmail.com</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
