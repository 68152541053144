import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Styles/Footer.css";

function Footerseminar() {
  const [states, setStates] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch("https://countriesnow.space/api/v0.1/countries/states", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ country: "India" })
    })
      .then(response => response.json())
      .then(data => {
        if (data.data) {
          setStates(data.data.states);
        }
      })
      .catch(error => {
        console.error("There was an error fetching the state list!", error);
      });
  }, []);

  const handleStateClick = (stateName) => {
    navigate(`/certification/${stateName}`);
  };

  const renderStateList = () => {
    const columns = [[], [], []]; // Array of 3 columns
    states.forEach((state, index) => {
      columns[index % 3].push(state.name); // Distribute states evenly across 3 columns
    });
    return columns.map((column, colIndex) => (
      <ul key={colIndex} className="ft-list-items">
        {column.map((stateName, index) => (
          <li key={index} onClick={() => handleStateClick(stateName)}>
            {stateName}
          </li>
        ))}
      </ul>
    ));
  };

  return (
    <div className="footer-section">
      <div className="footer-container">
        <div className="ft-info">
          <div className="ft-info-p1">
            <p className="ft-title">
              GCDA
            </p>
            <p className="ft-description">
              Provide expert career counselling to help individuals achieve their professional goals and find their true calling.
            </p>
          </div>
          <div className="ft-list" id="contact">
            <p className="ft-list-title">Talk To Us</p>
            <ul className="ft-list-items">
              <li>
                <a href="mailto:gcdassociation.org@gmail.com">gcdassociation.org@gmail.com</a>
              </li>
              <li>
                <a href="tel:91360 05039">91360 05039</a>
              </li>
              
              <li class="social-icons">
  <a href="https://www.facebook.com/share/gaMSkUUZhPpW2sZi/?mibextid=qi2Omg">
    <i class="fab fa-facebook-f"></i>
  </a>
  <a href="https://www.instagram.com/gcdaindia?igsh=cTJ0c3F0bXdmc3Jm">
    <i class="fab fa-instagram"></i>
  </a>
</li>
            </ul>
          </div>
        </div>

        <div className="ft-list">
          <p className="ft-list-title">Our Presence</p>
          <div className="ft-list-columns">
            {renderStateList()}
          </div>
        </div>
      </div>

      <div className="ft-copyright">
        <p>© 2013-2023 GCDA. All rights reserved.</p>
      </div>
    </div>
  );
}

export default Footerseminar;
