import React from "react";
import Header from "../Components/Header";
import Aboutus from '../Components/About-us';
import Footer from "../Components/Footer";





function About() {
  return (
    <div className="home-section">
      <Header />
      <Aboutus />
      <Footer />

    </div>
  );
}
export default About;
