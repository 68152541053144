import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../Styles/StatePage.css";
import Footerseminar from "../Components/Footerseminar";
import Navbar from "./Header";

function StatePage() {
  const { stateName } = useParams();
  const [cities, setCities] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    fetch(`https://api.gcdassociation.org/api/getstate/${stateName}`)
      .then(response => response.json())
      .then(data => {
        if (data.data) {
          setCities(data.data.map(city => city.city));
        }
      })
      .catch(error => {
        console.error("There was an error fetching the state details!", error);
      });
  }, [stateName]);

  useEffect(() => {
    // Check if the viewport width is less than a certain threshold (e.g., 600px)
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };
    // Initial check
    handleResize();
    // Add event listener for window resize
    window.addEventListener("resize", handleResize);
    // Cleanup function
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleCityClick = (cityName) => {
    const url = `https://gcdassociation.org/seminar/${stateName}/Career-counselling-seminar-${cityName}`;
    window.location.href = url;
  };

  return (
    <div>
      <Navbar />
      <div className="state-page">
        {/* Conditionally render grid or list based on viewport width */}
          <ul className="city-list">
            {cities.map((city, index) => (
              <li key={index} onClick={() => handleCityClick(city)}>
                {city}
              </li>
            ))}
          </ul>
       
      </div>
      <Footerseminar />
    </div>
  );
}

export default StatePage;
