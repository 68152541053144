import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import Home from "./Pages/Home";
import NotFound from "./Pages/NotFound";
import Data from './Components/city';
import About from './Pages/About-us';
import Plan from './Pages/Plan';
import Contacts from './Pages/Contact';
import Carercounselling from './Components/Career-Counsellor';
import StatePage from './Components/StatePage';
import Seminar from './Components/Seminar';
import Certification from './Components/Certification';
import Seminarworkshop from './Components/seminar-workshop';
import StatePageseminar from './Components/StatePageseminar';
import StatePageCertification from './Components/StatePageCertification';
import Certificationworkshop from './Components/certification-workshop';
import Area from './Components/area';

import { useParams } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router basename="/">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About-us" element={<About />} />
          <Route path="/Plan" element={<Plan />} />
          <Route path="/Career-Counsellor" element={<Carercounselling />} />
          <Route path="/Contacts" element={<Contacts />} />
          <Route
            path="/:state/:city"
            element={<Data />}
          />
            <Route
            path="/:state/:city/:area"
            element={<Area />}
          />
          <Route
            path="seminar/:state/:city"
            element={<Seminarworkshop />}
          />
          
          <Route path="/:stateName" element={<StatePage />} />
          <Route path="seminar/:stateName" element={<StatePageseminar />} />
          <Route path="/Career-counselling-seminar" element={<Seminar />} />
          <Route path="/Certification" element={<Certification />} />
          <Route path="certification/:stateName" element={<StatePageCertification />} />
          <Route
            path="certification/:state/:city"
            element={<Certificationworkshop />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
