import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from 'react-helmet';
import axios from 'axios';
import "../Styles/Hero.css";
import Doctor from "../Assets/bg-1.png";

function Hero() {
  const [goUp, setGoUp] = useState(false);
  const [apiData, setApiData] = useState({
    title1: '',
    title2: '',
  });

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const onPageScroll = () => {
      if (window.scrollY > 600) {
        setGoUp(true);
      } else {
        setGoUp(false);
      }
    };
    window.addEventListener("scroll", onPageScroll);

    return () => {
      window.removeEventListener("scroll", onPageScroll);
    };
  }, []);

  useEffect(() => {
    // Fetch API data
    axios.get('https://api.gcdassociation.org/api/getuidata/home')
      .then(response => {
        const data = response.data.data[0];
        setApiData({
          title1: data.title1,
          title2: data.title2,
        });
      })
      .catch(error => {
        console.error("There was an error fetching the API data!", error);
      });
  }, []);

  return (
    <div className="section-container">
      <Helmet>
        <title>Career Counselling Services | Best Career Counsellor</title>
        <meta name="description" content="Discover your path to success with our expert career counselling services. Personalized guidance, comprehensive assessments, and strategic advice to achieve your career goals." />
      </Helmet>
      <div
        className="hero-section"
        style={{
          backgroundImage: `url(${Doctor})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      >
        <div className="hero1-section">
          <p className="text-headline">Welcome</p>
          <h2 className="text-title">
            {apiData.title1}
          </h2>
          <p className="text-description">
            {apiData.title2}
          </p>

          <div className="text-stats">
            <div className="text-stats-container">
              <p>50k+</p>
              <p>Counsellings</p>
            </div>

            <div className="text-stats-container">
              <p>5k+</p>
              <p>Expert Counsellors</p>
            </div>

            <div className="text-stats-container">
              <p>10+</p>
              <p>Years of Experience</p>
            </div>
          </div>
        </div>
      </div>

     
    </div>
  );
}

export default Hero;
