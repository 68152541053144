import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import '../Styles/Testimonials.css'; // Import the CSS file

const CustomCard = styled(Card)(({ theme }) => ({
  padding: '20px',
  margin: '20px',
  border: '1px solid #070e10',
  borderRadius: '20px',
  overflow: 'hidden',
  backgroundColor: 'rgba(0, 0, 0, 0.7)', // Set background color to transparent black
  color: '#fff', // Set text color to white
  maxWidth: '300px',
  height: '300px',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
  },
}));

export default function Plan() {
  const [apiData, setApiData] = useState([]);

  useEffect(() => {
    axios.get('https://api.gcdassociation.org/api/getuidata/home')
      .then(response => {
        const data = response.data.data[0];
        setApiData([
          { description: data.title8, title: "Samantha H" },
          { description: data.title9, title: "Hirendra S" },
          { description: data.title10, title: "Tom H" },
        ]);
      })
      .catch(error => {
        console.error("There was an error fetching the API data!", error);
      });
  }, []);

  return (
    <div>
      <h2
        style={{
          borderBottom: "0.1px solid green",
          width: "180px",
          margin: "0 auto",
          color: "black",
          textAlign: "center",
          paddingTop: "20px"
        }}
      >
        Testimonials
      </h2>
      <div className="cards-container">
        {apiData.map((card, index) => (
          <CustomCard key={index} className="custom-card">
            <Typography sx={{ mb: 2 }} style={{ color: '#fff' }}>
              🔥 {card.description}
            </Typography>
            <Typography sx={{ mb: 4 }} style={{ color: 'green' }}>
              {card.title}
            </Typography>
          </CustomCard>
        ))}
      </div>
    </div>
  );
}
